/* eslint-disable react/prop-types */
/** @jsx jsx */
import React from 'react';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import { Box, Heading, jsx, AspectRatio } from 'theme-ui';
import { SEO } from '../utils';
import ContentContainer from '../components/content-container';
import PortableText from '../components/PortableText';

const Page = ({ data: { page } }) => {
  const ternaryObjectStyles = {
    objectPosition: page?.mainImage?.hotspot
      ? `${page.mainImage.hotspot.x * 100}% ${page.mainImage.hotspot.y * 100}%`
      : 'center center',
  };

  return (
    <>
      <SEO title={page.title} />
      <Box>
        {page?.mainImage?.asset?.fluid ? (
          <>
            <Img
              fluid={page.mainImage.asset.fluid}
              sx={{
                maxHeight: ['800px'],
              }}
              imgStyle={ternaryObjectStyles}
            />
          </>
        ) : (
          <AspectRatio
            ratio={20 / 2}
            sx={{
              p: 4,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              color: 'background',
              bg: 'primary',
            }}
          ></AspectRatio>
          //   <Heading
          //     as="h1"
          //     sx={{
          //       fontFamily: 'fancyFont',
          //       fontSize: [4, 5, '3.3rem'],
          //       pb: [3, 2, 0],
          //     }}
          //   >
          //     {page.title}
          //   </Heading>
        )}
        <ContentContainer>
          <Heading
            as="h1"
            sx={{ py: 3, fontFamily: 'fancyFont', fontSize: [5, '3.3rem'] }}
          >
            {page.title}
          </Heading>
          <PortableText blocks={page._rawBody} />
        </ContentContainer>
      </Box>
    </>
  );
};

export default Page;

export const query = graphql`
  query($slug: String!) {
    page: sanityPage(slug: { current: { eq: $slug } }) {
      title
      _rawBody(resolveReferences: { maxDepth: 5 })
      mainImage {
        hotspot {
          x
          y
        }
        asset {
          fluid(maxWidth: 1280) {
            ...GatsbySanityImageFluid
          }
        }
      }
    }
  }
`;
